.primary-button {
  background: $primary-200 !important;
  color: white !important;
  transition: 0.4s;
}

.primary-button:hover {
  background: $primary-500 !important;
}

.primary-button .mat-button-wrapper {
  vertical-align: middle !important;
}

.loading-background {
  background: $loading-background !important;
}

.separator-line {
  border-bottom: $text-border;
  margin: 10px 0;
  width: 100%;
  float: left;
}

.main-text-color {
  color: $main-text-color !important;
}

.mdc-notched-outline__notch {
  border-right: none !important;
}

.white {
  color: white !important;
}

.underline-hover:hover {
  text-decoration: underline;
}

.c-link:hover {
  cursor: pointer;
  text-decoration: none !important;
  opacity: 0.8;
}

.c-link {
  transition: 0.4s;
  text-decoration: underline;
  color: $primary-500 !important;
}

.swiper-lazy-preloader {
  border-bottom: $primary-500 !important;
  border-left-color: $primary-500 !important;
  border-right-color: $primary-500 !important;
}

.swiper-lazy {
  height: 100% !important;
  object-fit: cover;
}

.swiper-single-image {
  max-height: 207px;
  object-fit: cover;
}

.mat-mdc-form-field-icon-suffix {
  margin-right: 10px !important;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mdc-button__label,
.mat-mini-fab {
  font-family: Montserrat !important;
  font-size: 14px;
  font-weight: 500;
}

.mat-mdc-form-field-infix {
  min-height: 50px !important;
}

.mat-mdc-snack-bar-action {
  color: rgba(255, 255, 255, 0.87) !important;
}

::ng-deep .cdk-overlay-container {
  overflow: hidden;
  height: 100vh;
}

::ng-deep .mat-tooltip {
  overflow: visible !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  max-width: 80vw; // Passar að tooltipið verði ekki of breitt
  z-index: 1000; // Tryggir að tooltipið sé fyrir ofan önnur element
}

::ng-deep .cdk-overlay-connected-position-bounding-box {
  overflow: visible !important;
}

ngx-extended-pdf-viewer .pdfViewer .page {
  border: none !important;
  margin-top: 10px !important;
}

.tooltip-item {
  touch-action: auto !important;
}

.mdc-switch {
  min-width: 30px !important;
}
