@font-face {
    font-family: TeXGyreAdventorBold;
    src: url("fonts/texgyreadventor-bold.otf") format("opentype");
}

@font-face {
    font-family: TeXGyreAdventorBoldItalic;
    src: url("fonts/texgyreadventor-bolditalic.otf") format("opentype");
}

@font-face {
    font-family: TeXGyreAdventorItalic;
    src: url("fonts/texgyreadventor-italic.otf") format("opentype");
}

@font-face {
    font-family: TeXGyreAdventor;
    src: url("fonts/texgyreadventor-regular.otf") format("opentype");
}