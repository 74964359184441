@import "variables";

/******* Base styles *******/
* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.7;
}
p {
  margin-bottom: 16px;
}
a {
  outline: none;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.w-100 {
  width: 100% !important;
}
.mw-100 {
  max-width: 100%;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.d-block {
  display: block !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.transition {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.ws-nowrap {
  white-space: nowrap;
}
.m-auto {
  margin: 0 auto !important;
}
.o-hidden {
  overflow: hidden;
}

.theme-container {
  max-width: $theme-max-width;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}
.app-menu-panel.mat-mdc-menu-panel {
  min-width: 60px !important;
  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
  .mat-mdc-menu-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    .mat-badge-small.mat-badge-above .mat-badge-content {
      top: 8px;
    }
  }
  .mat-mdc-menu-content .mat-mdc-menu-item {
    .mdc-list-item {
      &__primary-text {
        display: flex;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
}
.social-icon {
  display: inherit;
  color: inherit;
  svg.mat-icon.mat-icon-lg {
    height: 37px;
  }
}

/******* Material Icons *******/
.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
  font-size: 14px !important;
  line-height: 14px !important;
  height: 14px !important;
  width: 14px !important;
  vertical-align: middle !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm {
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px !important;
  width: 18px !important;
  vertical-align: middle !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-md,
.mat-icon.mat-icon-md,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-md {
  font-size: 24px !important;
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
  vertical-align: middle !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
  font-size: 36px !important;
  line-height: 36px !important;
  height: 36px !important;
  width: 36px !important;
  vertical-align: middle !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
  font-size: 48px !important;
  line-height: 48px !important;
  height: 48px !important;
  width: 48px !important;
  vertical-align: middle !important;
}
.mat-icon.caret {
  width: 14px !important;
  overflow: visible;
  margin: 0 !important;
}

.mdc-button {
  &__label {
    display: flex;
    align-items: center;
  }
}

.mat-mdc-chip.uppercase {
  .mat-mdc-chip-action-label {
    text-transform: uppercase;
  }
}

.mat-mdc-snack-bar-container {
  button {
    font-size: 30px;
  }
}
.sidenav .mat-drawer-inner-container {
  overflow-x: hidden;
}

.horizontal-menu {
  .mat-mdc-button {
    padding: 0 16px;
    text-transform: uppercase;
  }
}
.horizontal-sub-menu {
  .mat-mdc-button {
    display: flex;
    place-content: flex-start;
    padding: 0 16px;
  }
  .mat-mdc-button,
  .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
    text-transform: uppercase;
    font-weight: 500;
  }
}

/******* Theme dialog *******/
.theme-dialog {
  .header {
    margin: -24px -24px 0px -24px;
    padding: 8px 8px 8px 24px;
  }
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-content {
      max-height: 80vh;
    }
  }
  &.fullscreen {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    transform: none !important;
    .mat-mdc-dialog-container {
      overflow: hidden;
      .mat-mdc-dialog-content {
        max-height: calc(100vh - 56px);
      }
    }
  }
}

/******* Header *******/
#main-toolbar {
  position: relative;
  height: $main-toolbar-height;
  transition: 0.2s;
  z-index: 9;
}
.top-toolbar.mat-toolbar {
  height: $top-toolbar-height;
  font-size: 12px;
  .v-divider {
    width: 1px;
    height: 26px;
    margin: 0 16px;
  }
  .mat-mdc-button {
    font-size: 12px;
    padding: 0 10px;
    color: inherit;
    border-radius: 0;
  }
}
.logo {
  img {
    display: block;
    width: auto;
    max-width: 100%;
  }
  svg {
    display: block;
  }
}
.user-menu {
  width: $user-menu-width;
  .user-info {
    padding: 16px;
    img {
      border-radius: 4px;
      margin-right: 16px;
    }
  }
}
.flag-name {
  margin-left: 4px;
}

header {
  &.toolbar-2 {
    .top-toolbar.mat-toolbar {
      height: auto;
      .mat-toolbar-row:first-child {
        height: $top-toolbar-height;
        .right-section {
          height: $top-toolbar-height;
        }
      }
      .mat-toolbar-row:nth-child(2) {
        height: auto;
        font-size: 14px;
        font-style: italic;
        padding-top: 8px;
        padding-bottom: $top-toolbar-height + 8px;
        .item {
          width: 100%;
          white-space: normal;
          line-height: 1.5;
          padding: 0 8px;
        }
      }
    }
  }
  &.has-bg-image,
  &.has-bg-video {
    #main-toolbar {
      box-shadow: none;
    }
    &.main-toolbar-fixed {
      #main-toolbar {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      }
    }
    &:not(.main-toolbar-fixed) {
      #main-toolbar {
        background: transparent !important;
      }
    }
  }
}

/******* Pages *******/
.main-content-header {
  position: relative;
  z-index: 2;
  margin-top: 24px;
  padding: 24px !important;
}
main.content-offset-to-top {
  .main-content-header {
    margin-top: -$main-content-header-offset-to-top;
  }
}

/******* APP *******/
.app {
  height: 100%;
  .page-wrapper {
    min-height: 100vh;
  }
  &.main-toolbar-fixed {
    #main-toolbar {
      position: fixed;
      top: 0px;
      width: 100%;
    }
    .page-wrapper {
      padding-top: $main-toolbar-height;
    }
  }
}

/******* Properties *******/
.properties-wrapper {
  margin: 8px -8px;
  .item {
    padding: 8px;
  }
}
.price {
  &::first-letter {
    padding-right: 4px;
  }
}
.all-properties {
  .search-sidenav {
    float: left;
    position: relative;
    margin-bottom: 16px;
    width: $search-sidenav-width;
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    .mat-drawer-inner-container {
      padding: 2px;
      height: 100%;
      box-sizing: border-box;
      .mat-mdc-card {
        height: 100%;
        box-sizing: border-box;
      }
    }
  }
  .mat-drawer-content {
    min-height: 600px;
    padding: 2px;
    overflow: hidden;
    &.distance {
      padding-left: 16px;
    }
  }
  .mat-drawer-backdrop {
    bottom: 16px;
  }
  .mat-mdc-paginator-outer-container {
    font-size: 14px;
  }
}

.search-wrapper {
  margin: -8px;
  .mat-mdc-form-field {
    width: 100%;
  }
  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    padding: 0;
  }
  &.variant-3 {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
  button {
    min-width: 120px;
  }
}

/******* Single property *******/
.single-property {
  .mat-drawer {
    width: 360px;
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    .mat-drawer-inner-container {
      padding: 2px;
      height: 100%;
      box-sizing: border-box;
      .mat-mdc-card {
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
      }
      button.close {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 1;
      }
    }
    .ng-scroll-content {
      display: block;
    }
  }
  .mat-drawer-content {
    padding: 2px;
    &.distance {
      padding-right: 16px;
    }
    .details {
      .item {
        span:first-child {
          margin-right: 8px;
          font-weight: 500;
          min-width: 114px;
          display: inline-block;
        }
        .list {
          span {
            font-weight: normal;
            min-width: auto;
            &:not(.last):after {
              content: ",";
            }
            &.last:after {
              content: none;
            }
          }
        }
      }
    }
    .mat-expansion-panel-body {
      text-align: center;
    }
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/******* Comments *******/
.mat-mdc-list.reviews {
  .mdc-list-item {
    margin-bottom: 24px;
    &__content {
      white-space: unset;
      overflow: unset;
      p {
        margin-bottom: 0;
        &.text {
          white-space: unset;
          font-style: italic;
          margin-top: 8px;
        }
      }
    }
    &__primary-text {
      white-space: unset;
    }
  }
  .mat-mdc-list-item .mat-mdc-list-item-avatar.review-author {
    width: 80px;
    height: 80px;
    align-self: flex-start;
  }
}
.comment-form {
  margin: -16px;
  padding: 8px;
}

/******* Pricing *******/
.pricing-tabs.mat-mdc-tab-group {
  margin: 0 -8px;
}
.pricing-tabs.mat-mdc-tab-group .mat-mdc-tab-header {
  border-bottom: none !important;
  margin-bottom: 16px;
}
.pricing-card.mat-mdc-card {
  padding: 0;
  overflow: hidden;
  border-width: 4px 0 0 0;
  border-style: solid;
  .pricing-title {
    text-transform: uppercase;
    font-weight: 500;
  }
  .pricing-header {
    min-height: 126px;
    small {
      font-weight: normal;
    }
  }
  .del {
    text-decoration: line-through;
  }
}

/******* Account *******/
.account {
  .mat-drawer {
    width: 280px;
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    .mat-drawer-inner-container {
      padding: 2px;
      height: 100%;
      box-sizing: border-box;
      .mat-mdc-card {
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
      button.close {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 1;
      }
    }
  }
  .mat-drawer-content {
    padding: 2px;
    min-height: 400px;
    &.distance {
      padding-left: 16px;
    }
  }
}
.table-wrapper {
  display: block;
  overflow-x: auto;
  td.mat-mdc-cell,
  th.mat-mdc-header-cell {
    white-space: nowrap;
    padding: 16px;
  }
  a.property-name {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
  }
}

/******* Submit Property *******/
.submit-property {
  .step-content {
    max-width: 760px;
    margin: 0 auto;
  }
  .step-section {
    padding: 20px 10px 0;
    border-radius: 4px;
    margin: 8px 8px 16px;
  }
}

/******* Compare Property *******/
.compare-item {
  .mdc-evolution-chip-set .mdc-evolution-chip-set__chips,
  .mdc-evolution-chip-set .mdc-evolution-chip {
    margin: 0;
  }
  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic
    .mdc-evolution-chip__action--primary {
    margin: 0 auto;
  }
}

/******* Footer *******/
footer {
  position: relative;
  overflow: hidden;
  background: rgba(33, 33, 33, 0.85);
  color: #fff;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("~../assets/e-fasteignir-images/footer.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .content {
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    .logo svg {
      fill: #fff;
    }
    .desc {
      color: rgba(255, 255, 255, 0.7);
    }
    .custom-form {
      .mdc-text-field:not(.mdc-text-field--disabled) {
        .mdc-text-field__input {
          color: #fff;
          &::placeholder {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
      .mdc-text-field:not(.mdc-text-field--disabled, .mdc-text-field--invalid) {
        .mdc-floating-label {
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .mdc-text-field--outlined:not(
          .mdc-text-field--disabled,
          .mdc-text-field--invalid
        )
        .mdc-notched-outline__leading,
      .mdc-text-field--outlined:not(
          .mdc-text-field--disabled,
          .mdc-text-field--invalid
        )
        .mdc-notched-outline__notch,
      .mdc-text-field--outlined:not(
          .mdc-text-field--disabled,
          .mdc-text-field--invalid
        )
        .mdc-notched-outline__trailing {
        border-color: rgba(255, 255, 255, 0.3);
      }

      .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
          .mdc-text-field--focused
        ):hover
        .mdc-notched-outline
        .mdc-notched-outline__leading,
      .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
          .mdc-text-field--focused
        ):hover
        .mdc-notched-outline
        .mdc-notched-outline__notch,
      .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
          .mdc-text-field--focused
        ):hover
        .mdc-notched-outline
        .mdc-notched-outline__trailing {
        border-color: #fff;
      }

      .subscribe-input {
        height: 56px;
      }
      .subscribe-btn {
        margin-left: -4px;
        border-radius: 0 4px 4px 0;
        text-transform: uppercase;
        height: auto;
      }
    }
  }
  .copyright {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    p {
      margin: 0;
    }
  }
}

/******* Carousel *******/
button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none !important;
  &.transparent {
    background: transparent !important;
    box-shadow: none;
  }
}
.swiper-pagination.white .swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  vertical-align: -1px;
}
.carousel-outer {
  margin: -2px;
  padding: 2px;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: none;
}

/******* Section *******/
.section {
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  padding: 40px 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.15;
  }
  &.testimonials {
    &:before {
      background-image: url("~../assets/images/props/flat-1/3-big.jpg");
    }
  }
  &.agents {
    &:before {
      background-image: url("~../assets/images/props/office-2/4-big.jpg");
    }
  }
  &.default {
    &:before {
      background-image: url("~../assets/images/others/default-bg.png");
      background-repeat: repeat;
      background-size: 350px;
      background-position: center;
      opacity: 1;
    }
  }
  .section-title {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 160px;
      margin: 0 auto;
      height: 2px;
    }
  }
}

/******* Landing Page *******/
.landing-page .logo svg {
  fill: #fff;
}

/******* Responsive *******/
// xs
@media screen and (max-width: 599px) {
  .single-property {
    .mat-drawer {
      width: 320px;
    }
  }
}

// sm
@media screen and (min-width: 600px) and (max-width: 959px) {
}

@media screen and (max-width: 959px) {
  .top-toolbar.mat-toolbar {
    padding: 0;
  }
  .all-properties {
    .search-sidenav {
      float: none;
      position: absolute;
      .mat-drawer-inner-container {
        padding: 0px;
        .mat-mdc-card {
          box-shadow: none;
          border-radius: 0;
        }
      }
      ng-scrollbar {
        box-shadow: none !important;
      }
    }
  }
  .single-property,
  .account {
    .mat-drawer {
      .mat-drawer-inner-container {
        padding: 0px;
        .mat-mdc-card {
          box-shadow: none;
          border-radius: 0;
        }
      }
    }
    ng-scrollbar {
      box-shadow: none !important;
    }
  }
  .submit-property .mat-step-label {
    display: none;
  }
  .theme-dialog {
    min-width: 95%;
  }
}

// md
@media screen and (min-width: 960px) and (max-width: 1279px) {
}

// lg
@media screen and (min-width: 1280px) and (max-width: 1919px) {
}

// xlg
@media screen and (min-width: 1920px) and (max-width: 5000px) {
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #082a54;
  border: 0px none #082a54;
  border-radius: 0px;
  &:hover {
    background: #082a54b3;
  }
  &:active {
    background: #082a54b3;
  }
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #666666;
  }
  &:active {
    background: #333333;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}

//----------ng-scrollbar---------------
ng-scrollbar {
  --scrollbar-size: 6px !important;
  --scrollbar-thumb-color: rgba(204, 204, 204, 0.8) !important;
  --scrollbar-thumb-hover-color: #ccc !important;
  --scrollbar-border-radius: 4px !important;
  --scrollbar-hover-size: 8px !important;
  --scrollbar-padding: 2px !important;
}
