.fade-in-left {
    position: relative;
    overflow-x: visible;
    -webkit-animation: fade-in-left 0.5s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fade-in-left 0.5s;
    /* Firefox < 16 */
    -ms-animation: fade-in-left 0.5s;
    /* Internet Explorer */
    -o-animation: fade-in-left 0.5s;
    /* Opera < 12.1 */
    animation: fade-in-left 0.5s;
}

@keyframes fade-in-left {
    0% {
        left: -1400px;
        opacity: 0;
    }

    50% {
        left: -30px;
        opacity: 0.50;
    }

    100% {
        left: 0px;
        opacity: 1 !important;
    }
}

/* Firefox < 16 */
@-moz-keyframes fade-in-left {
    0% {
        left: -1400px;
        opacity: 0;
    }

    50% {
        left: -30px;
        opacity: 0.50;
    }

    100% {
        left: 0px;
        opacity: 1 !important;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fade-in-left {
    0% {
        left: -1400px;
        opacity: 0;
    }

    50% {
        left: -30px;
        opacity: 0.50;
    }

    100% {
        left: 0px;
        opacity: 1 !important;
    }
}

/* Internet Explorer */
@-ms-keyframes fade-in-left {
    0% {
        left: -1400px;
        opacity: 0;
    }

    50% {
        left: -30px;
        opacity: 0.50;
    }

    100% {
        left: 0px;
        opacity: 1 !important;
    }
}

/* Opera < 12.1 */
@-o-keyframes fade-in-left {
    0% {
        left: -1400px;
        opacity: 0;
    }

    50% {
        left: -30px;
        opacity: 0.50;
    }

    100% {
        left: 0px;
        opacity: 1 !important;
    }
}

.fade-in-width {
    -webkit-animation: fade-in-width 0.7s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fade-in-width 0.7s;
    /* Firefox < 16 */
    -ms-animation: fade-in-width 0.7s;
    /* Internet Explorer */
    -o-animation: fade-in-width 0.7s;
    /* Opera < 12.1 */
    animation: fade-in-width 0.7s;
}

@keyframes fade-in-width {
    0% {
        opacity: 0;
        width: 120%;
    }

    50% {
        opacity: 0.65;
        width: 105%;
    }

    100% {
        opacity: 1 !important;
        width: 100%;
    }
}

/* Firefox < 16 */
@-moz-keyframes fade-in-width {
    0% {
        opacity: 0;
        width: 120%;
    }

    50% {
        opacity: 0.65;
        width: 105%;
    }

    100% {
        opacity: 1 !important;
        width: 100%;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fade-in-width {
    0% {
        opacity: 0;
        width: 120%;
    }

    50% {
        opacity: 0.65;
        width: 105%;
    }

    100% {
        opacity: 1 !important;
        width: 100%;
    }
}

/* Internet Explorer */
@-ms-keyframes fade-in-width {
    0% {
        opacity: 0;
        width: 120%;
    }

    50% {
        opacity: 0.65;
        width: 105%;
    }

    100% {
        opacity: 1 !important;
        width: 100%;
    }
}

/* Opera < 12.1 */
@-o-keyframes fade-in-width {
    0% {
        opacity: 0;
        width: 120%;
    }

    50% {
        opacity: 0.65;
        width: 105%;
    }

    100% {
        opacity: 1 !important;
        width: 100%;
    }
}

.fade-in {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    opacity: 1 !important;
}

.fade-out {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    opacity: 0 !important;
}

.fade-out-faster {
    -webkit-transition: opacity 0.1s ease-in-out;
    -moz-transition: opacity 0.1s ease-in-out;
    -ms-transition: opacity 0.1s ease-in-out;
    -o-transition: opacity 0.1s ease-in-out;
    opacity: 0 !important;
}

.fade-in-fast {
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    opacity: 1 !important;
}

.fade-in-faster {
    -webkit-transition: opacity 0.1s ease-in-out;
    -moz-transition: opacity 0.1s ease-in-out;
    -ms-transition: opacity 0.1s ease-in-out;
    -o-transition: opacity 0.1s ease-in-out;
    opacity: 1 !important;
}

.fade-out-fast {
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    opacity: 0 !important;
}

.slide-rotate-hor-top {
    -webkit-animation: slide-rotate-hor-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-rotate-hor-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


// OUT

.flip-out-hor-top {
    -webkit-animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:48:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}

@keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}



.slide-out-left {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-26 21:10:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

.slit-out-horizontal {
    -webkit-animation: slit-out-horizontal 0.5s ease-in both;
    animation: slit-out-horizontal 0.5s ease-in both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-26 21:17:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-out-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes slit-out-horizontal {
    0% {
        -webkit-transform: translateZ(0) rotateX(0);
        transform: translateZ(0) rotateX(0);
        opacity: 1;
    }

    54% {
        -webkit-transform: translateZ(-160px) rotateX(87deg);
        transform: translateZ(-160px) rotateX(87deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-800px) rotateX(90deg);
        transform: translateZ(-800px) rotateX(90deg);
        opacity: 0;
    }
}

@keyframes slit-out-horizontal {
    0% {
        -webkit-transform: translateZ(0) rotateX(0);
        transform: translateZ(0) rotateX(0);
        opacity: 1;
    }

    54% {
        -webkit-transform: translateZ(-160px) rotateX(87deg);
        transform: translateZ(-160px) rotateX(87deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-800px) rotateX(90deg);
        transform: translateZ(-800px) rotateX(90deg);
        opacity: 0;
    }
}













/* ----------------------------------------------
 * Generated by Animista on 2020-3-4 20:23:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-rotate-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-hor-top {
    0% {
        -webkit-transform: translateY(0) rotateX(0deg);
        transform: translateY(0) rotateX(0deg);
    }

    100% {
        -webkit-transform: translateY(-300px) rotateX(-90deg);
        transform: translateY(-300px) rotateX(-90deg);
    }
}

@keyframes slide-rotate-hor-top {
    0% {
        -webkit-transform: translateY(0) rotateX(0deg);
        transform: translateY(0) rotateX(0deg);
    }

    100% {
        -webkit-transform: translateY(-300px) rotateX(-90deg);
        transform: translateY(-300px) rotateX(-90deg);
    }
}

.rotate-vert-center {
    -webkit-animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-4 20:25:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-vert-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-vert-center {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes rotate-vert-center {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

.slide-fwd-center {
    -webkit-animation: slide-fwd-center 3.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-fwd-center 3.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:16:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }

    100% {
        -webkit-transform: translateZ(160px);
        transform: translateZ(160px);
    }
}

@keyframes slide-fwd-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }

    100% {
        -webkit-transform: translateZ(160px);
        transform: translateZ(160px);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:24:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-scale-up-hor
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-up-hor {
    0% {
        -webkit-transform: scale(1) rotateX(0);
        transform: scale(1) rotateX(0);
    }

    50% {
        -webkit-transform: scale(2.5) rotateX(-90deg);
        transform: scale(2.5) rotateX(-90deg);
    }

    100% {
        -webkit-transform: scale(1) rotateX(-180deg);
        transform: scale(1) rotateX(-180deg);
    }
}

@keyframes flip-scale-up-hor {
    0% {
        -webkit-transform: scale(1) rotateX(0);
        transform: scale(1) rotateX(0);
    }

    50% {
        -webkit-transform: scale(2.5) rotateX(-90deg);
        transform: scale(2.5) rotateX(-90deg);
    }

    100% {
        -webkit-transform: scale(1) rotateX(-180deg);
        transform: scale(1) rotateX(-180deg);
    }
}



.flip-scale-up-hor {
    -webkit-animation: flip-scale-up-hor 0.5s linear both;
    animation: flip-scale-up-hor 0.5s linear both;
}


.slit-in-vertical {
    -webkit-animation: slit-in-vertical 0.35s ease-out both;
    animation: slit-in-vertical 0.35s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:27:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-in-vertical
 * ----------------------------------------
 */
@-webkit-keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }

    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}

@keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }

    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}



.slide-in-bck-center {
    -webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:30:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-center {
    0% {
        -webkit-transform: translateZ(600px);
        transform: translateZ(600px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes slide-in-bck-center {
    0% {
        -webkit-transform: translateZ(600px);
        transform: translateZ(600px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}



.rotate-in-center {
    -webkit-animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:42:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-center {
    0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotate-in-center {
    0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}


.slide-in-elliptic-top-fwd {
    -webkit-animation: slide-in-elliptic-top-fwd 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-elliptic-top-fwd 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:43:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}

@keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}


.tilt-in-fwd-tr {
    -webkit-animation: tilt-in-fwd-tr 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tilt-in-fwd-tr 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:45:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-tr {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

@keyframes tilt-in-fwd-tr {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

.flip-out-hor-top {
    -webkit-animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-21 16:10:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}

@keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}


.flip-in-hor-bottom {
    -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 13:47:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-bottom {
    0% {
        -webkit-transform: rotateX(80deg);
        transform: rotateX(80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}

@keyframes flip-in-hor-bottom {
    0% {
        -webkit-transform: rotateX(80deg);
        transform: rotateX(80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}



.swing-in-top-bck {
    -webkit-animation: swing-in-top-bck 0.6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-top-bck 0.6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 18:38:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-bck {
    0% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

@keyframes swing-in-top-bck {
    0% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

.rotate-in-center {
    -webkit-animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 18:41:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-center {
    0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotate-in-center {
    0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

.rotate-in-2-bck-cw {
    -webkit-animation: rotate-in-2-bck-cw 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-in-2-bck-cw 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-8 18:43:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-2-bck-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-bck-cw {
    0% {
        -webkit-transform: translateZ(200px) rotate(-45deg);
        transform: translateZ(200px) rotate(-45deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0) rotate(0);
        transform: translateZ(0) rotate(0);
        opacity: 1;
    }
}

@keyframes rotate-in-2-bck-cw {
    0% {
        -webkit-transform: translateZ(200px) rotate(-45deg);
        transform: translateZ(200px) rotate(-45deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0) rotate(0);
        transform: translateZ(0) rotate(0);
        opacity: 1;
    }
}


.puff-in-center {
    -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-9 13:40:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

.swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 1.3s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-top-fwd 1.3s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-9 13:42:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

@keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

.slide-in-tr {
    -webkit-animation: slide-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-21 16:7:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-tr {
    0% {
        -webkit-transform: translateY(-1000px) translateX(1000px);
        transform: translateY(-1000px) translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-tr {
    0% {
        -webkit-transform: translateY(-1000px) translateX(1000px);
        transform: translateY(-1000px) translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}

.tilt-in-left-2 {
    -webkit-animation: tilt-in-left-2 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tilt-in-left-2 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-21 16:18:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-left-2
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-left-2 {
    0% {
        -webkit-transform: rotateX(30deg) translateX(-300px) skewX(30deg);
        transform: rotateX(30deg) translateX(-300px) skewX(30deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}

@keyframes tilt-in-left-2 {
    0% {
        -webkit-transform: rotateX(30deg) translateX(-300px) skewX(30deg);
        transform: rotateX(30deg) translateX(-300px) skewX(30deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}

.slide-in-top {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-21 16:21:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-in-top-slow {
    -webkit-animation: slide-in-top-slow 0.85s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top-slow 0.85s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-21 16:21:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top-slow {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-tr {
    -webkit-animation: slide-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-3-22 14:16:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-tr {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }

    100% {
        -webkit-transform: translateY(-100px) translateX(100px);
        transform: translateY(-100px) translateX(100px);
    }
}

@keyframes slide-tr {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }

    100% {
        -webkit-transform: translateY(-100px) translateX(100px);
        transform: translateY(-100px) translateX(100px);
    }
}

.flip-in-diag-2-br {
    -webkit-animation: flip-in-diag-2-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: flip-in-diag-2-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-22 14:22:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-diag-2-br
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-diag-2-br {
    0% {
        -webkit-transform: rotate3d(-1, 1, 0, -80deg);
        transform: rotate3d(-1, 1, 0, -80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
        transform: rotate3d(1, 1, 0, 0deg);
        opacity: 1;
    }
}

@keyframes flip-in-diag-2-br {
    0% {
        -webkit-transform: rotate3d(-1, 1, 0, -80deg);
        transform: rotate3d(-1, 1, 0, -80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
        transform: rotate3d(1, 1, 0, 0deg);
        opacity: 1;
    }
}

.puff-in-center {
    -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-23 20:39:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

.rotate-in-2-cw {
    -webkit-animation: rotate-in-2-cw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-in-2-cw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-23 20:41:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-2-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-cw {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotate-in-2-cw {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

.slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-23 20:43:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.swing-out-top-bck {
    -webkit-animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
    animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 10:49:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-out-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-top-bck {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
}

@keyframes swing-out-top-bck {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
}

.puff-out-center {
    -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
    animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 13:50:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }
}

@keyframes puff-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }
}

.slide-out-bck-br {
    -webkit-animation: slide-out-bck-br 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bck-br 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 13:52:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bck-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-br {
    0% {
        -webkit-transform: translateZ(0) translateY(0) translateX(0);
        transform: translateZ(0) translateY(0) translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
        transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
        opacity: 0;
    }
}

@keyframes slide-out-bck-br {
    0% {
        -webkit-transform: translateZ(0) translateY(0) translateX(0);
        transform: translateZ(0) translateY(0) translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
        transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
        opacity: 0;
    }
}

.swing-out-top-fwd {
    -webkit-animation: swing-out-top-fwd 0.55s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
    animation: swing-out-top-fwd 0.55s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 13:54:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-out-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-top-fwd {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
}

@keyframes swing-out-top-fwd {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
}


.slide-in-br {
    -webkit-animation: slide-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 13:56:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-br {
    0% {
        -webkit-transform: translateY(1000px) translateX(1000px);
        transform: translateY(1000px) translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-br {
    0% {
        -webkit-transform: translateY(1000px) translateX(1000px);
        transform: translateY(1000px) translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}

.slide-out-bck-center {
    -webkit-animation: slide-out-bck-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bck-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 13:58:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-1100px);
        transform: translateZ(-1100px);
        opacity: 0;
    }
}

@keyframes slide-out-bck-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-1100px);
        transform: translateZ(-1100px);
        opacity: 0;
    }
}

.rotate-in-2-bl-cw {
    -webkit-animation: rotate-in-2-bl-cw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-in-2-bl-cw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 21:10:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-2-bl-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-bl-cw {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
        opacity: 1;
    }
}

@keyframes rotate-in-2-bl-cw {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
        opacity: 1;
    }
}

.tilt-in-left-1 {
    -webkit-animation: tilt-in-left-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tilt-in-left-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 21:13:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-left-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-left-1 {
    0% {
        -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
        transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}

@keyframes tilt-in-left-1 {
    0% {
        -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
        transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}

.scale-out-tl {
    -webkit-animation: scale-out-tl 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-tl 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 21:14:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-tl
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-tl {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }
}

@keyframes scale-out-tl {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }
}

.slide-out-bck-bottom {
    -webkit-animation: slide-out-bck-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bck-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 21:17:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bck-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-bottom {
    0% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-1100px) translateY(1000px);
        transform: translateZ(-1100px) translateY(1000px);
        opacity: 0;
    }
}

@keyframes slide-out-bck-bottom {
    0% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-1100px) translateY(1000px);
        transform: translateZ(-1100px) translateY(1000px);
        opacity: 0;
    }
}

.flip-out-hor-top {
    -webkit-animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 21:18:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}

@keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}

.rotate-in-center {
    -webkit-animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 21:20:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-center {
    0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotate-in-center {
    0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

.slide-in-fwd-tr {
    -webkit-animation: slide-in-fwd-tr 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-tr 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 21:24:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-tr {
    0% {
        -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
        transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0) translateX(0);
        transform: translateZ(0) translateY(0) translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-tr {
    0% {
        -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
        transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0) translateY(0) translateX(0);
        transform: translateZ(0) translateY(0) translateX(0);
        opacity: 1;
    }
}

.roll-in-right {
    -webkit-animation: roll-in-right 0.6s ease-out both;
    animation: roll-in-right 0.6s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-29 21:27:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-right {
    0% {
        -webkit-transform: translateX(800px) rotate(540deg);
        transform: translateX(800px) rotate(540deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-right {
    0% {
        -webkit-transform: translateX(800px) rotate(540deg);
        transform: translateX(800px) rotate(540deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}


.slide-in-bck-left {
    -webkit-animation: slide-in-bck-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bck-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-31 19:25:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bck-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-left {
    0% {
        -webkit-transform: translateZ(700px) translateX(-400px);
        transform: translateZ(700px) translateX(-400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0) translateX(0);
        transform: translateZ(0) translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-bck-left {
    0% {
        -webkit-transform: translateZ(700px) translateX(-400px);
        transform: translateZ(700px) translateX(-400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0) translateX(0);
        transform: translateZ(0) translateX(0);
        opacity: 1;
    }
}


.slide-in-blurred-top {
    -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 7:50:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

.slide-in-fwd-center {
    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 7:54:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}


.fade-in-fwd {
    -webkit-animation: fade-in-fwd 1.0s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 1.0s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 7:55:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.roll-in-top {
    -webkit-animation: roll-in-top 0.6s ease-out both;
    animation: roll-in-top 0.6s ease-out both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-21 13:26:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-top
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-top {
    0% {
        -webkit-transform: translateY(-800px) rotate(-540deg);
        transform: translateY(-800px) rotate(-540deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-top {
    0% {
        -webkit-transform: translateY(-800px) rotate(-540deg);
        transform: translateY(-800px) rotate(-540deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
}

.tilt-in-right-1 {
    -webkit-animation: tilt-in-right-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tilt-in-right-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-21 13:27:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-right-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-right-1 {
    0% {
        -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
        transform: rotateX(-30deg) translateX(300px) skewX(30deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}

@keyframes tilt-in-right-1 {
    0% {
        -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
        transform: rotateX(-30deg) translateX(300px) skewX(30deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}

.bounce-in-fwd {
    -webkit-animation: bounce-in-fwd 1.1s both;
    animation: bounce-in-fwd 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-24 22:11:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-fwd {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: scale(0.84);
        transform: scale(0.84);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    89% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-fwd {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: scale(0.84);
        transform: scale(0.84);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    89% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.swing-in-bottom-fwd {
    -webkit-animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-26 21:5:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-bottom-fwd {
    0% {
        -webkit-transform: rotateX(100deg);
        transform: rotateX(100deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        opacity: 1;
    }
}

@keyframes swing-in-bottom-fwd {
    0% {
        -webkit-transform: rotateX(100deg);
        transform: rotateX(100deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        opacity: 1;
    }
}

.tilt-in-fwd-bl {
    -webkit-animation: tilt-in-fwd-bl 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tilt-in-fwd-bl 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-26 21:7:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-bl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-bl {
    0% {
        -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
        transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

@keyframes tilt-in-fwd-bl {
    0% {
        -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
        transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

.fade-in-bottom {
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-26 21:8:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-in-left {
    -webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-26 21:9:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.rotate-in-2-fwd-cw {
    -webkit-animation: rotate-in-2-fwd-cw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-in-2-fwd-cw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-30 9:59:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-2-fwd-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-fwd-cw {
    0% {
        -webkit-transform: translateZ(-200px) rotate(-45deg);
        transform: translateZ(-200px) rotate(-45deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0) rotate(0);
        transform: translateZ(0) rotate(0);
        opacity: 1;
    }
}

@keyframes rotate-in-2-fwd-cw {
    0% {
        -webkit-transform: translateZ(-200px) rotate(-45deg);
        transform: translateZ(-200px) rotate(-45deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0) rotate(0);
        transform: translateZ(0) rotate(0);
        opacity: 1;
    }
}

.swing-in-left-bck {
    -webkit-animation: swing-in-left-bck 0.6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-left-bck 0.6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-30 10:0:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-left-bck {
    0% {
        -webkit-transform: rotateY(-70deg);
        transform: rotateY(-70deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1;
    }
}

@keyframes swing-in-left-bck {
    0% {
        -webkit-transform: rotateY(-70deg);
        transform: rotateY(-70deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1;
    }
}

.tilt-in-fwd-tl {
    -webkit-animation: tilt-in-fwd-tl 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tilt-in-fwd-tl 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-30 10:45:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-tl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-tl {
    0% {
        -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px) skew(35deg, -10deg);
        transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px) skew(35deg, -10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

@keyframes tilt-in-fwd-tl {
    0% {
        -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px) skew(35deg, -10deg);
        transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px) skew(35deg, -10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

.flip-out-ver-left {
    -webkit-animation: flip-out-ver-left 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: flip-out-ver-left 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-30 13:49:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-out-ver-left
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-ver-left {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateY(-70deg);
        transform: rotateY(-70deg);
        opacity: 0;
    }
}

@keyframes flip-out-ver-left {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateY(-70deg);
        transform: rotateY(-70deg);
        opacity: 0;
    }
}

.flip-horizontal-bottom {
    -webkit-animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-30 14:0:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-horizontal-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-horizontal-bottom {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
    }

    100% {
        -webkit-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
    }
}

@keyframes flip-horizontal-bottom {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
    }

    100% {
        -webkit-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
    }
}

.rotate-out-2-br-ccw {
    -webkit-animation: rotate-out-2-br-ccw 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-out-2-br-ccw 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-30 14:4:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-out-2-br-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-br-ccw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
}

@keyframes rotate-out-2-br-ccw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
}

.tilt-in-fwd-br {
    -webkit-animation: tilt-in-fwd-br 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tilt-in-fwd-br 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-30 15:4:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-br
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-br {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
        transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

@keyframes tilt-in-fwd-br {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
        transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

.slide-in-blurred-right {
    -webkit-animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-1 18:23:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-right {
    0% {
        -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-right {
    0% {
        -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}


.slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-16 12:3:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.roll-in-blurred-left {
    -webkit-animation: roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-16 12:5:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) rotate(-720deg);
        transform: translateX(-1000px) rotate(-720deg);
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes roll-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) rotate(-720deg);
        transform: translateX(-1000px) rotate(-720deg);
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

.fade-in-right {
    -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-19 12:49:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}


.fade-in-top {
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-9-25 16:41:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-4 21:57:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}