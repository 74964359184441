// ------------ MAIN COLORS -------------
$primary-light-blue: #4695E7;
$primary-lighter-blue: #5FAFFB;
$primary-blue: #253a85;
$primary-dark-blue: #082A54;

$primary-200: #082A54;
$primary-500: #253a85;
$primary-800: #082A54;

// Content
$main-content-color: #f7f7f7;
$light-weight-content: #f9fafd;
$main-text-color: rgba(0, 0, 0, 0.87);
$loading-background: rgb(17, 24, 39);

$gray-white: #d1ecf1;
$white: rgb(255, 255, 255);
$white-weak: rgb(255, 255, 255, 0.5);
$white-hover: rgb(212, 212, 212);
$darker-white: #fefefe;
$mat-title-color: #1e293b;

// GrayWhite
$gray-white-color: #e9e9e9;
$gray-white-color-hover: #f6f6f6;

// --------------------------------

// ------------- ALERT-------------
// Alert Dark Background
$alert-b-color: #323130;

// Alert color
$alert-color: rgb(111, 2, 1);
$alert-text-color: rgb(255, 255, 255);

// Notification color
$notification-color: #FA3E3E;

// Alert yellow color
$alert-yellow-color: #F6BE00;
$alert-yellow-color-light: rgb(253, 244, 164);
$alert-b-color: #323130;

$text-border: 1px solid rgba(0, 0, 0, 0.1);
$box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .1);

$gray-scale-color: rgb(233, 235, 238);
$gray-logo-color-light: rgb(173, 173, 173);
$gray-logo-color: rgb(132, 132, 132);
$success-color: rgb(83, 189, 120);

$confirm-color-m: #53bd78;
$alert-color-m: rgba(239, 191, 0, 1);
$alert-safe: #eed202;
$confirm-color-m-weak: rgba(81, 189, 119, 0.129);
$alert-color-m-weak: rgba(239, 191, 0, 0.11);

$panel-color: #323130;
$lighter-dark: #3c4146;

$basic-gray: #f1f5f9;
$basic-fuse-gray: #64748b;

$form-color: rgb(148, 168, 184);

// --------------------------------